// import React, { useMemo, useState, createContext } from "react";
import CryptoJS from "crypto-js";
import { Cookies } from "react-cookie";

// export const RootContext = createContext({
//   tokenHash: "",
// });

const getCookieHash = () => {
  const cookie = new Cookies();
  const hashed = CryptoJS.MD5(
    CryptoJS.lib.WordArray.create(cookie.get("token"))
  );

  return hashed.toString();
};

// const RootProvider = ({ children }) => {
//   return (
//     <RootContext.Provider value={{ getCookieHash, tokenHash: getCookieHash() }}>
//       {children}
//     </RootContext.Provider>
//   );
// };

export default getCookieHash;
