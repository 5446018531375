import React, { memo, useContext, useEffect, useRef, useState } from "react";
// import { OverlayTrigger, Popover } from "react-bootstrap"
import { NavLink, useHistory } from "react-router-dom";
import {
  // FaUserAlt,
  // FaThLarge,
  FaCar,
  // FaFileContract,
  FaEnvelopeOpenText,
  // FaSms,
  // FaBoxes,
  FaTasks,
  // FaReceipt,
  FaPenNib,
  FaHandshake,
  FaUsers,
  FaUsersCog,
  FaWrench,
  FaRoad,
  FaBullhorn,
  FaTachometerAlt,
  FaDollarSign,
  // FaSms,
  // FaEnvelopeOpen,
  // FaComments,
  FaCommentAlt,
  FaCalculator,
  FaAudioDescription,
  FaRegEnvelopeOpen,
  FaAngleDown,
  FaRegHandshake,
  FaCarCrash,
} from "react-icons/fa";
import { FaBagShopping } from "react-icons/fa6";
import { SiQuickbooks } from "react-icons/si";
import { MdSocialDistance } from "react-icons/md";
import { TbReport } from "react-icons/tb";
import { useDrawerSetter, useDrawerState } from "../../hooks/useDrawer";
import HillzLogoIcon from "../common/svgs/hillz_logo_svg";
import { SocketContext } from "../../context/socket";
import { useCookies } from "react-cookie";
import { useIsAuthState } from "../../hooks/useAuth";
import { IMAGE_CDN_DOMAIN } from "../../constant/base";
import moment from "moment/moment";
import { BsFillChatSquareQuoteFill } from "react-icons/bs";

const AdminDrawer = () => {
  const drawer = useDrawerState();

  const setDrawerState = useDrawerSetter();
  const { socket, setSocket, chat } = useContext(SocketContext);
  const [{ token }] = useCookies(["token"]);
  const user = useIsAuthState().user;
  const users = useIsAuthState();
  const history = useHistory();
  const [unreadMassageCount, setunreadMassageCount] = useState("");
  const [showConfirm, setShowConfirm] = useState(false);

  const [isExpanded, setIsExpanded] = useState(false);
  const [isPurchaseExpanded, setIsPurchaseExpanded] = useState(false);
  const divRef = useRef(null);
  const divPurchaseRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setIsExpanded(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        divPurchaseRef.current &&
        !divPurchaseRef.current.contains(event.target)
      ) {
        setIsPurchaseExpanded(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (!socket.connected && token) {
      setSocket((prevSocket) => {
        prevSocket.auth = { isPanel: true, token, isWeb: true };
        const newSocket = prevSocket.connect();
        newSocket.on("message:get", (newMessage) => {
          if (Notification.permission === "granted") {
            const room = newMessage.ChatRoom;
            const title = room?.creator.is_guest
              ? room.creator_ip
              : room?.creator.username;

            const icon = newMessage?.ChatRoom?.creator?.avatar
              ? `${IMAGE_CDN_DOMAIN}${newMessage.ChatRoom.creator.avatar}`
              : undefined;

            const notification = new Notification(title, {
              body: newMessage.message,
              icon,
            });

            notification.addEventListener("click", () => {
              window.focus();
              notification.close();
              history.push("/admin/chat", newMessage.roomId);
            });
          }
        });

        return newSocket;
      });

      setSocket((prevSocket) => prevSocket.connect());
    }

    // return () => setSocket(() => socket.disconnect());
  }, [history, setSocket, socket, token]);

  const dayLimit = 10;
  const [showElement, setShowElement] = useState(true);
  // console.log(Date())
  const initialDate = "Aug 31 2024 00:00:00";
  // console.log(showElement)

  useEffect(() => {
    if (
      users?.dealershipInfo?.id === 882 ||
      users?.dealershipInfo?.id === 490
    ) {
      const diffTime = moment().diff(initialDate);
      const diffMinutes = Math.floor(diffTime / (1000 * 60 * 60 * 24)); // Calculate difference in minutes
      console.log({ diffTime, diffMinutes });
      if (diffMinutes >= dayLimit) {
        setShowElement(false);
      } else {
        setShowElement(true);
      }
    }
  }, [users, initialDate]);

  return (
    <>
      {/* <Modal
        show={showConfirm}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        contentClassName="mt-5"
      >
        <Modal.Header className="vehicle_modal_header-style border-0">
          <Modal.Title className="inner_header">
            Purchase From Public
          </Modal.Title>
          <button
            className="btn-close"
            onClick={() => {
              handleModalClose();
            }}
          ></button>
        </Modal.Header>
        <Modal.Body>
          <AddPurchesPublic
            setShowConfirm={setShowConfirm}

            // getSingleCreatWizardBillOfSaleQuery={
            //   getSingleCreatWizardBillOfSaleQuery
            // }
          />{" "}
        </Modal.Body>
      </Modal> */}
      <div
        className={`d-flex flex-column justify-content-start align-items-start sidenav-container ${
          drawer ? "sidenavOpen" : "sidenavClose"
        } `}
        onMouseOver={() => setDrawerState(true)}
        onMouseLeave={() => setDrawerState(false)}
      >
        <div className="p-2 m-0 pt-1 mb-5 sidebar_ul d-flex flex-column justify-content-center align-items-start">
          <NavLink
            to="/admin"
            className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start"
            // activeClassName="sidebar_navlink"
            onClick={() => {
              setDrawerState(false);
              localStorage.setItem("statusFilter", 1);
              localStorage.setItem("setPage", 0);
            }}
          >
            {/* <i className="sidebar_icons d-flex align-items-center justify-content-center"> */}

            <FaTachometerAlt className="sidebar_icons" />

            {/* </i> */}
            <p
              className={`${
                drawer || isExpanded === true || isPurchaseExpanded === true
                  ? ""
                  : "d-none"
              } p-0 m-0`}
              style={{ fontSize: "1.1rem" }}
            >
              DASHBOARD
            </p>
          </NavLink>
          <NavLink
            to="/admin/vehicle-managment"
            className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start "
            activeClassName="sidebar_navlink"
            onClick={() => {
              setDrawerState(false);
              localStorage.setItem("statusFilter", 1);
              localStorage.setItem("setPage", 0);
            }}
          >
            <FaCar className="sidebar_icons" />
            <p
              className={`${
                drawer || isExpanded === true || isPurchaseExpanded === true
                  ? ""
                  : "d-none"
              } p-0 m-0`}
              style={{ fontSize: "1.1rem" }}
            >
              INVENTORY
            </p>
          </NavLink>
          {[1, 250, 817, 877, 949].includes(users?.dealershipInfo?.id) && (
            <NavLink
              to="/admin/shop"
              className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start "
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
                localStorage.setItem("statusFilter", 1);
                localStorage.setItem("setPage", 0);
              }}
            >
              <FaBagShopping className="sidebar_icons" />
              {/* <FaBagShopping /> */}
              <p
                className={`${
                  drawer || isExpanded === true || isPurchaseExpanded === true
                    ? ""
                    : "d-none"
                } p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                SHOP
              </p>
            </NavLink>
          )}
          {users?.user?.frk_role !== 15 && users?.user?.frk_role !== 22 && (
            <div className="w-100 p-0 m-0">
              <div
                ref={divPurchaseRef}
                className=" text-decoration-none d-flex flex-wrap d-flex justify-content-start align-items-start w-100 p-0 m-0"
              >
                <div
                  className={`px-1 text-decoration-none icon-container sidebar_li d-flex justify-content-start align-items-center ${
                    isPurchaseExpanded ? "active" : ""
                  }`}
                >
                  <span
                    className="p-0 m-0 d-flex w-100 flex-wrap "
                    onClick={(e) => {
                      setDrawerState(false);
                      localStorage.setItem("statusFilter", 1);
                      localStorage.setItem("setPage", 0);
                      setIsPurchaseExpanded(!isPurchaseExpanded);
                    }}
                  >
                    <div className="p-1 sidebar_li_2 d-flex flex-wrap m-0 w-100 align-items-center">
                      <img
                        src="/images/car.svg"
                        style={{
                          width: "40px",
                          height: "35px",
                        }}
                        className="car-icon"
                        alt="icon"
                      />

                      <img
                        src="/images/car2.svg"
                        style={{
                          width: "40px",
                          height: "35px",
                        }}
                        className="car2-icon"
                        alt="icon"
                      />

                      <p
                        className={`${
                          drawer ||
                          isExpanded === true ||
                          isPurchaseExpanded === true
                            ? ""
                            : "d-none"
                        } p-0 m-0`}
                        style={{
                          fontSize: "1.1rem",
                          marginLeft: "15px !important",
                        }}
                      >
                        <span style={{ marginLeft: "-10px" }}>Purchases</span>
                        <FaAngleDown style={{ marginLeft: "20px" }} />
                      </p>
                    </div>
                  </span>
                </div>
              </div>
              {isPurchaseExpanded && (
                <div>
                  <NavLink
                    to="/admin/purche-from-public"
                    className="text-decoration-none style_nav_itemes d-flex justify-content-start align-items-start "
                    onClick={() => {
                      setDrawerState(false);
                      setIsPurchaseExpanded(!isPurchaseExpanded);
                      localStorage.setItem("statusFilter", 1);
                      localStorage.setItem("setPage", 0);
                    }}
                  >
                    <ul className="px-0 w-100 m-0">
                      <ul className="py-0 py-1 m-0 px-0">
                        <li className="px-2" style={{ fontSize: "14px" }}>
                          {" "}
                          Purchase From Public
                        </li>
                      </ul>
                    </ul>
                  </NavLink>
                  <NavLink
                    to="/admin/purchase-from-dealer"
                    className="text-decoration-none style_nav_itemes d-flex justify-content-start align-items-start "
                    onClick={() => {
                      setDrawerState(false);
                      setIsPurchaseExpanded(!isPurchaseExpanded);
                      localStorage.setItem("statusFilter", 1);
                      localStorage.setItem("setPage", 0);
                    }}
                  >
                    <ul className="p-0 w-100 m-0">
                      {/* <strong
                        className=" p-0 m-0 d-flex justify-content-center blink_animation align-items-center "
                        style={{
                          width: "30px",
                          height: "30px",
                          fontSize: "17px",
                          fontWeight: "900 !important",
                          borderRadius: "5px",
                          position: "absolute",
                          zIndex: "2",
                          // top: "80.5%",
                          left: "20px",
                          color: "#28fdf3",
                        }}
                      >
                        new
                      </strong> */}
                      <li className="px-2 py-1" style={{ fontSize: "14px" }}>
                        Purchase From Dealer
                      </li>
                    </ul>
                  </NavLink>
                </div>
              )}
            </div>
          )}
          {users?.user?.frk_role !== 15 &&
            users?.user?.frk_role !== 22 &&
            users?.dealershipInfo?.added_wanted_permit && (
              <NavLink
                to="/admin/added-wanted"
                className="p-1  text-decoration-none icon-container sidebar_li d-flex justify-content-start align-items-center"
                activeClassName="sidebar_navlink"
                onClick={() => {
                  setDrawerState(false);
                  localStorage.setItem("statusFilter", 1);
                  localStorage.setItem("setPage", 0);
                }}
              >
                <img
                  src="/images/added-2.png"
                  style={{
                    width: "40px",
                    height: "35px",
                  }}
                  className="car-icon"
                  alt="icon"
                />

                <img
                  src="/images/added-1.png"
                  style={{
                    width: "40px",
                    height: "35px",
                  }}
                  className="car2-icon"
                  alt="icon"
                />

                <p
                  className={`${
                    drawer || isExpanded === true || isPurchaseExpanded === true
                      ? ""
                      : "d-none"
                  } p-0 m-0`}
                  style={{ fontSize: "1.1rem", marginLeft: "15px !important" }}
                >
                  <span style={{ marginLeft: "-10px" }}>Added and Wanted</span>
                </p>
              </NavLink>
            )}

          {[1, 250, 923, 693, 981, 490, 882].includes(
            users?.dealershipInfo?.id
          ) &&
            showElement && (
              <NavLink
                to="/admin/mechanic-shop"
                className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start "
                activeClassName="sidebar_navlink"
                onClick={() => {
                  setDrawerState(false);
                  localStorage.setItem("statusFilter", 1);
                  localStorage.setItem("setPage", 0);
                }}
              >
                <FaCarCrash className="sidebar_icons" />
                <p
                  className={`${
                    drawer || isExpanded === true || isPurchaseExpanded === true
                      ? ""
                      : "d-none"
                  } p-0 m-0`}
                  style={{ fontSize: "1.1rem" }}
                >
                  Mechanic Shop
                </p>
              </NavLink>
            )}
          {users?.dealershipInfo?.id !== 467 &&
          users?.user?.frk_role !== 15 &&
          users?.user?.frk_role !== 22 ? (
            <NavLink
              to="/admin/all-cars"
              className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start "
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
                localStorage.setItem("statusFilter", 1);
                localStorage.setItem("setPage", 0);
              }}
            >
              {/* <i className="sidebar_icons d-flex align-items-center justify-content-center"> */}

              <FaHandshake className="sidebar_icons" />

              {/* </i> */}
              <p
                className={`${
                  drawer || isExpanded === true || isPurchaseExpanded === true
                    ? ""
                    : "d-none"
                } p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                DEAL
              </p>
            </NavLink>
          ) : undefined}
          <NavLink
            to="/lead-center"
            className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start w-100"
            activeClassName="sidebar_navlink"
            onClick={() => {
              setDrawerState(false);
              localStorage.setItem("statusFilter", 1);
              localStorage.setItem("setPage", 0);
            }}
          >
            <FaEnvelopeOpenText className="sidebar_icons" />
            <p
              className={`${
                drawer || isExpanded === true || isPurchaseExpanded === true
                  ? ""
                  : "d-none"
              } p-0 m-0`}
              style={{ fontSize: "1.1rem" }}
            >
              LEAD
            </p>
          </NavLink>
          {/* {user?.frk_dealer_ship_id === 250 ||
          user?.frk_dealer_ship_id === 1 ||
          user?.frk_dealer_ship_id !== 467 ||
          user?.frk_dealer_ship_id === 369 ||
          user?.frk_dealer_ship_id === 393 ? (
            // ||
            // user?.frk_dealer_ship_id === 369
            <NavLink
              to="/market-center"
              className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start w-100"
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
                
              }}
            >
              <FaRegEnvelopeOpen className="sidebar_icons" />
              <p
                className={`${
                  drawer  === true ? "" : "d-none"
                } p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                MARKET LEAD
              </p>
            </NavLink>
          ) : null} */}
          {users?.user?.frk_role !== 15 && users?.user?.frk_role !== 22 && (
            <NavLink
              to="/admin/all-customers"
              className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start "
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
                localStorage.setItem("statusFilter", 1);
                localStorage.setItem("setPage", 0);
              }}
            >
              <FaUsers className="sidebar_icons" />
              <p
                className={`${
                  drawer || isExpanded === true || isPurchaseExpanded === true
                    ? ""
                    : "d-none"
                } p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                CUSTOMERS
              </p>
            </NavLink>
          )}
          {![5, 15, 22, 17].includes(users?.user?.frk_role) && (
            <NavLink
              to="/admin/quotation/list"
              className="p-1 px-2 text-decoration-none position-relative sidebar_li d-flex justify-content-start align-items-start "
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
                localStorage.setItem("statusFilter", 1);
                localStorage.setItem("setPage", 0);
              }}
            >
              <BsFillChatSquareQuoteFill className="sidebar_icons" />
              <p
                className={`${
                  drawer || isExpanded === true || isPurchaseExpanded === true
                    ? ""
                    : "d-none"
                } p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                QUOTATION
              </p>
              <b
                className=" p-0 m-0 d-flex justify-content-center blink_animation align-items-center "
                style={{
                  width: "25px",
                  height: "25px",
                  fontSize: "13px",
                  borderRadius: "5px",
                  position: "absolute",
                  zIndex: "2",
                  top: "-20px",
                  left: "10px",
                  color: "#fff",
                }}
              >
                new
              </b>
            </NavLink>
          )}
          {users?.dealershipInfo?.id !== 467 &&
          users?.user?.frk_role !== 15 &&
          users?.user?.frk_role !== 22 ? (
            <NavLink
              to="/admin/dealer-real-test-drive"
              className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start w-100"
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
                localStorage.setItem("statusFilter", 1);
                localStorage.setItem("setPage", 0);
              }}
            >
              <FaRoad className="sidebar_icons" />
              <p
                className={`${
                  drawer || isExpanded === true || isPurchaseExpanded === true
                    ? ""
                    : "d-none"
                } p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                TEST DRIVE
              </p>
            </NavLink>
          ) : undefined}
          {users?.user?.frk_role !== 15 && users?.user?.frk_role !== 22 && (
            <NavLink
              to="/admin/task-managment"
              className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start w-100"
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
                localStorage.setItem("statusFilter", 1);
                localStorage.setItem("setPage", 0);
              }}
            >
              <FaTasks className="sidebar_icons" />
              <p
                className={`${
                  drawer || isExpanded === true || isPurchaseExpanded === true
                    ? ""
                    : "d-none"
                } p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                TASKS
              </p>
            </NavLink>
          )}
          {users?.dealershipInfo?.id !== 467 &&
          users?.user?.frk_role !== 15 &&
          users?.user?.frk_role !== 22 ? (
            <NavLink
              to="/admin/vehicle-vendor"
              className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start w-100"
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
                localStorage.setItem("statusFilter", 1);
                localStorage.setItem("setPage", 0);
              }}
            >
              <FaWrench className="sidebar_icons" />
              <p
                className={`${
                  drawer || isExpanded === true || isPurchaseExpanded === true
                    ? ""
                    : "d-none"
                } p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                VENDORS
              </p>
            </NavLink>
          ) : undefined}
          {users?.dealershipInfo?.id !== 467 &&
          users?.user?.frk_role !== 15 &&
          users?.user?.frk_role !== 22 ? (
            <NavLink
              to="/admin/campain-managment"
              className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start w-100"
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
                localStorage.setItem("statusFilter", 1);
                localStorage.setItem("setPage", 0);
              }}
            >
              <FaBullhorn className="sidebar_icons" />
              <p
                className={`${
                  drawer || isExpanded === true || isPurchaseExpanded === true
                    ? ""
                    : "d-none"
                } p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                CAMPAIGN
              </p>
            </NavLink>
          ) : undefined}
          {users?.dealershipInfo?.id !== 467 &&
          users?.user?.frk_role !== 15 &&
          users?.user?.frk_role !== 22 ? (
            <NavLink
              to="/blog"
              className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start w-100"
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
                localStorage.setItem("statusFilter", 1);
                localStorage.setItem("setPage", 0);
              }}
            >
              <FaPenNib className="sidebar_icons" />
              <p
                className={`${
                  drawer || isExpanded === true || isPurchaseExpanded === true
                    ? ""
                    : "d-none"
                } p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                BLOG
              </p>
            </NavLink>
          ) : undefined}
          {users?.user?.id === 154097 &&
          users?.dealershipInfo?.id === 856 ? null : (
            <NavLink
              to="/admin/user-managment"
              className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start w-100"
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
                localStorage.setItem("statusFilter", 1);
                localStorage.setItem("setPage", 0);
              }}
            >
              <FaUsersCog className="sidebar_icons" />
              <p
                className={`${
                  drawer || isExpanded === true || isPurchaseExpanded === true
                    ? ""
                    : "d-none"
                } p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                USERS
              </p>
            </NavLink>
          )}
          {users?.dealershipInfo?.id !== 467 &&
          users?.user?.frk_role !== 15 &&
          users?.user?.frk_role !== 22 ? (
            <NavLink
              to="/admin/general-cost"
              className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start w-100"
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
                localStorage.setItem("statusFilter", 1);
                localStorage.setItem("setPage", 0);
              }}
            >
              <FaDollarSign className="sidebar_icons" />
              <p
                className={`${
                  drawer || isExpanded === true || isPurchaseExpanded === true
                    ? ""
                    : "d-none"
                } p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                GENERAL COST
              </p>
            </NavLink>
          ) : undefined}

          {[575, 801, 986, 859].includes(users?.dealershipInfo?.id) ||
          (users?.dealershipInfo?.id !== 467 &&
            users?.user?.frk_role !== 15 &&
            users?.user?.frk_role !== 22 &&
            ![20].includes(users?.user?.frk_role) &&
            users?.dealershipInfo?.DsContracts &&
            users?.dealershipInfo?.DsContracts[0]?.Package?.id === 4) ? (
            //  &&
            // users?.dealershipInfo?.DsContracts &&
            // users?.dealershipInfo?.DsContracts[0]?.Package?.id === 4
            // <NavLink
            //   to="/admin/accounting"
            //   className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start w-100"
            //   activeClassName="sidebar_navlink"
            //   onClick={() => {
            //     setDrawerState(false);
            //   }}
            // >
            //   <FaCalculator className="sidebar_icons" />
            //   <p
            //      className={`${
            //   drawer || isExpanded === true || isPurchaseExpanded === true? "" : "d-none"
            // } p-0 m-0`}
            //     style={{ fontSize: "1.1rem" }}
            //   >
            //     ACCOUNTING
            //   </p>
            // </NavLink>
            <div className="w-100">
              <div
                ref={divRef}
                className=" text-decoration-none d-flex flex-wrap sidebar_li d-flex justify-content-start align-items-start w-100"
                // onClick={() => {
                //   setIsExpanded(!isExpanded);
                // }}
              >
                <span
                  className="p-0 m-0 d-flex w-100 flex-wrap "
                  onClick={(e) => {
                    setIsExpanded(!isExpanded);
                  }}
                >
                  <div
                    className={` py-1 px-2 sidebar_li_2 d-flex flex-wrap m-0 w-100 ${
                      isExpanded ? "active  " : " "
                    }`}
                  >
                    <FaCalculator className="sidebar_icons" />
                    <p
                      className={`${
                        drawer ||
                        isExpanded === true ||
                        isPurchaseExpanded === true
                          ? ""
                          : "d-none"
                      } p-0 m-0`}
                      style={{ fontSize: "1.1rem" }}
                    >
                      ACCOUNTING
                      <FaAngleDown style={{ marginLeft: "20px" }} />
                    </p>
                  </div>
                </span>
              </div>
              {isExpanded && (
                <div>
                  <NavLink
                    to="/admin/accounting"
                    className="text-decoration-none style_nav_itemes d-flex justify-content-start align-items-start "
                    onClick={() => {
                      setDrawerState(false);
                      setIsExpanded(!isExpanded);
                      localStorage.setItem("statusFilter", 1);
                      localStorage.setItem("setPage", 0);
                    }}
                  >
                    <ul className="px-0 w-100 m-0">
                      <ul className="py-0 py-1 m-0 px-0">
                        <li className="px-2" style={{ fontSize: "14px" }}>
                          {" "}
                          ACCOUNTING
                        </li>
                      </ul>
                    </ul>
                  </NavLink>
                  <NavLink
                    to="/admin/write_cheque"
                    className="text-decoration-none style_nav_itemes d-flex justify-content-start align-items-start "
                    onClick={() => {
                      setDrawerState(false);
                      setIsExpanded(!isExpanded);
                      localStorage.setItem("statusFilter", 1);
                      localStorage.setItem("setPage", 0);
                    }}
                  >
                    <ul className="p-0 w-100 m-0">
                      {/* <strong
                        className=" p-0 m-0 d-flex justify-content-center blink_animation align-items-center "
                        style={{
                          width: "30px",
                          height: "30px",
                          fontSize: "17px",
                          fontWeight: "900 !important",
                          borderRadius: "5px",
                          position: "absolute",
                          zIndex: "2",
                          // top: "80.5%",
                          left: "20px",
                          color: "#28fdf3",
                        }}
                      >
                        new
                      </strong> */}
                      <li className="px-2 py-1" style={{ fontSize: "14px" }}>
                        WRITE THE CHEQUE{" "}
                      </li>
                    </ul>
                  </NavLink>
                </div>
              )}
            </div>
          ) : null}
          {
            users?.dealershipInfo?.has_chat === 1 && (
              // users?.user?.frk_role !== 15 ? (
              <NavLink
                to="/admin/chat"
                className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start w-100"
                activeClassName="sidebar_navlink"
                onClick={() => {
                  setDrawerState(false);
                  localStorage.setItem("statusFilter", 1);
                  localStorage.setItem("setPage", 0);
                }}
              >
                <FaCommentAlt className="sidebar_icons" />
                <p
                  className={`${
                    drawer || isExpanded === true || isPurchaseExpanded === true
                      ? ""
                      : "d-none"
                  } p-0 m-0 d-flex flex-row justify-content-between w-100 align-items-center`}
                  style={{ fontSize: "1.1rem" }}
                >
                  CHAT{" "}
                  {+chat.unreadRoomsCount !== 0 && (
                    <b
                      className=" p-0 m-0 d-flex justify-content-center align-items-center "
                      style={{
                        width: "25px",
                        height: "25px",
                        fontSize: "14px",
                        borderRadius: "5px",
                      }}
                    >
                      {chat.unreadRoomsCount}
                    </b>
                  )}
                </p>
              </NavLink>
            )
            // ) : null
          }
          {/* {user?.frk_dealer_ship_id === 250 ||
        user?.frk_dealer_ship_id === 1 ||
        user?.frk_dealer_ship_id === 408 ||
        user?.frk_dealer_ship_id === 335 ||
        user?.frk_dealer_ship_id === 425 ||
        user?.frk_dealer_ship_id === 385 ||
        user?.frk_dealer_ship_id === 409 ||
        user?.frk_dealer_ship_id === 425 ? ( */}
          {users?.dealershipInfo?.has_syndication === 1 &&
          users?.user?.frk_role !== 15 &&
          users?.user?.frk_role !== 22 ? (
            <NavLink
              to="/admin/advertising"
              className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start w-100"
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
                localStorage.setItem("statusFilter", 1);
                localStorage.setItem("setPage", 0);
              }}
            >
              <FaAudioDescription className="sidebar_icons" />
              <p
                className={`${
                  drawer || isExpanded === true || isPurchaseExpanded === true
                    ? ""
                    : "d-none"
                } p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                Syndication
              </p>
            </NavLink>
          ) : null}
          {/* <NavLink
          to='/admin/chat'
          className='p-0 text-decoration-none sidebar_li d-flex justify-content-start align-items-start w-100'
          activeClassName='sidebar_navlink'
          onClick={() => {
            setDrawerState(false)
          }}
        >
          <HillzLogoIcon />
          <p
            className={`${drawer ||isExpanded === true || isPurchaseExpanded === true? "" : "d-none"} p-0 m-0`}
            style={{ fontSize: "1.1rem" }}
          >
            HILLZ
          </p>
        </NavLink> */}
          {(users?.dealershipInfo?.quickbooks_permit === 1 ||
            users?.dealershipInfo?.id === 1 ||
            users?.dealershipInfo?.id === 250) && (
            <NavLink
              to="/admin/quick-books/account"
              className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start w-100"
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
                localStorage.setItem("statusFilter", 1);
                localStorage.setItem("setPage", 0);
              }}
            >
              <SiQuickbooks className="sidebar_icons" />
              <p
                className={`${
                  drawer || isExpanded === true || isPurchaseExpanded === true
                    ? ""
                    : "d-none"
                } p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                QuickBooks
              </p>
            </NavLink>
          )}
          {users?.dealershipInfo?.id === 856 ||
          users?.dealershipInfo?.id === 1 ||
          users?.dealershipInfo?.id === 250 ? (
            <NavLink
              to="/admin/social-posting"
              className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start w-100"
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
                localStorage.setItem("statusFilter", 1);
                localStorage.setItem("setPage", 0);
              }}
            >
              <MdSocialDistance className="sidebar_icons" />
              <p
                className={`${
                  drawer || isExpanded === true || isPurchaseExpanded === true
                    ? ""
                    : "d-none"
                } p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                Social Posting
              </p>
            </NavLink>
          ) : null}
          {users?.user?.id === 154097 &&
          users?.dealershipInfo?.id === 856 ? null : (
            <NavLink
              to="/admin/hillz"
              className="p-0 px-1 text-decoration-none sidebar_li d-flex justify-content-start align-items-center "
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
                localStorage.setItem("statusFilter", 1);
                localStorage.setItem("setPage", 0);
              }}
            >
              <HillzLogoIcon />

              <p
                className={`${
                  drawer || isExpanded === true || isPurchaseExpanded === true
                    ? ""
                    : "d-none"
                } p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                HILLZ
              </p>
            </NavLink>
          )}
          {users?.user?.frk_role !== 15 && users?.user?.frk_role !== 22 && (
            <NavLink
              to="/admin/reports-generate"
              className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start w-100"
              activeClassName="sidebar_navlink"
              onClick={() => {
                setDrawerState(false);
                localStorage.setItem("statusFilter", 1);
                localStorage.setItem("setPage", 0);
              }}
            >
              <TbReport className="sidebar_icons" />

              <p
                className={`${
                  drawer || isExpanded === true || isPurchaseExpanded === true
                    ? ""
                    : "d-none"
                } p-0 m-0`}
                style={{ fontSize: "1.1rem" }}
              >
                REPORTS
              </p>
            </NavLink>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(AdminDrawer);
