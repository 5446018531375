import moment from "moment"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { v4 as uuid } from "uuid"
import { ApiCallUploadMethodFormData } from "../constant/base"
import { useIsAuthState } from "./useAuth"

const useChat = (socket, status, setStatus, history) => {
  const socketRef = useRef(null)
  const user = useIsAuthState().user





  // States
  const [rooms, setRooms] = useState([])
  const [messages, setMessages] = useState(null)
  const [selectedChat, setSelectedChat] = useState()
  const [typing, setTyping] = useState({})

  const [nextPage, setNextPage] = useState("")
  const [roomsCount, setRoomsCount] = useState(0)
  const [unreadRoomsCount, setUnreadRoomsCount] = useState(0)


  // Utils
  const sendMessage = useCallback(
    ({ message, to, online, messageType, messageFile }) => {
      const creation_id = uuid()

      const createMessage = ({
        from,
        messageType,
        src,
        loading,
        mediaHeight,
        mediaWidth,
        originalFileName,
      }) => ({
        id: uuid(),
        creation_id,
        createdAt: moment().utc(false),
        updatedAt: moment().utc(false),
        from,
        message,
        fromType: "ADMIN",
        messageType: messageType,
        roomId: to,
        status: online ? "received" : "sent",
        src,
        loading,
        mediaHeight,
        mediaWidth,
        originalFileName,
      })

      switch (messageType) {
        case "text": {
          socketRef.current?.emit("message:newAdminMessage", {
            to,
            message,
            creation_id,
            messageType: "text",
          })

          setMessages((prev) => {
            const newMessage = createMessage({
              from: user.id,
              messageType: "text",
              loading: false,
            })

            if (prev) return [...prev, newMessage]
            else return [newMessage]
          })
          break
        }
        case "image": {
          const formData = new FormData()
          const reader = new FileReader()

          reader.readAsDataURL(messageFile)
          formData.append("chatFile", messageFile)

          reader.addEventListener("load", () => {
            const img = new Image()
            img.src = reader.result

            img.onload = () => {
              const width = img.width
              const height = img.height

              setMessages((prev) => [
                ...prev,
                createMessage({
                  from: user.id,
                  messageType: "image",
                  src: reader.result,
                  loading: true,
                  mediaHeight: height,
                  mediaWidth: width,
                }),
              ])

              ApiCallUploadMethodFormData(
                "api/chat/admin/uploadImage/",
                "POST",
                formData
              ).then((image) => {
                socketRef.current?.emit("message:newAdminMessage", {
                  to,
                  message,
                  creation_id,
                  file: image,
                  mediaHeight: height,
                  mediaWidth: width,
                  messageType: "image",
                })

                setMessages((prev) => {
                  const index = prev.findIndex(
                    (m) => m.creation_id === creation_id
                  )

                  return [
                    ...prev.slice(0, index),
                    {
                      ...prev[index],
                      loading: false,
                      src: image.path,
                    },
                    ...prev.slice(index + 1),
                  ]
                })
              })
            }
          })

          break
        }
        case "doc": {
          const formData = new FormData()
          formData.append("chatFile", messageFile)

          setMessages((prev) => [
            ...prev,
            createMessage({
              from: user.id,
              messageType: "doc",
              loading: true,
              src: null,
              originalFileName: messageFile.name,
            }),
          ])

          ApiCallUploadMethodFormData(
            "api/chat/admin/uploadImage/",
            "POST",
            formData
          ).then((pdf) => {
            socketRef.current?.emit("message:newAdminMessage", {
              to,
              message,
              creation_id,
              file: pdf,
              messageType: "doc",
            })

            setMessages((prev) => {
              const index = prev.findIndex((m) => m.creation_id === creation_id)

              return [
                ...prev.slice(0, index),
                {
                  ...prev[index],
                  loading: false,
                  src: pdf.path,
                  originalFileName: pdf?.name,
                },
                ...prev.slice(index + 1),
              ]
            })
          })

          break
        }
        default: {
          break
        }
      }
    },
    [user?.id]
  )


  const loadNextPage = useCallback(
    () => socketRef.current?.emit("rooms:fetch", nextPage),
    [nextPage]
  )
  const getRoomsCount = useCallback(
    () => socketRef.current?.emit("rooms:count"),
    [status]
  )
  const getRoomById = useCallback(
    (id) => rooms.find((room) => room.id === id),
    [rooms]
  )
  const setUserStatus = useCallback(
    (selectedStatus) => {
      switch (selectedStatus) {
        case "online": {
          socketRef.current?.emit("status:online")
          socketRef.current?.emit("rooms:refetch")
          getAllRooms()
          break
        }
        case "offline": {
          socketRef.current?.emit("status:offline")

          break
        }
        case "logout": {
          socketRef.current?.emit("status:logout")
          break
        }
        case "toggle": {
          const toggledStatus = status === "online" ? "offline" : "online"
          socketRef.current?.emit(`status:${toggledStatus}`)
          break
        }

        default: {
          break
        }
      }
      getStatus()
    },
    [status]
  )
  const getStatus = useCallback(
    () => {

      socketRef.current?.emit("status:check")
    },
    [user?.id]
  )

  // Handlers
  // const newUserJoined = useCallback((room) => {
  //   socketRef.current?.emit("admin:join", room.name)

  //   setRooms((prev) => {
  //     const exist = prev.findIndex(({ id }) => room.id === id) !== -1
  //     if (!exist) return [room, ...prev]
  //     return prev
  //   })
  // }, [])

  const handleNewMessage = useCallback(
    (newMessage) => {
      if (newMessage.roomId === selectedChat) {
        setMessages((prev) => {
          if (!prev) return [newMessage]
          else return [...prev, newMessage]
        })
      }
    },
    [selectedChat]
  )

  const handleUpdateMessage = useCallback((newMessage) => {
    setMessages((prev) => {
      if (prev) {
        return prev.map((m) => {
          if (m.creation_id === newMessage.creation_id) return newMessage
          else return m
        })
      }

      return prev
    })
  }, [])

  const handleGetMessages = useCallback((dataMessages) => {
    setMessages(() => dataMessages)
  }, [])

  const handleGetRooms = useCallback(
    ({ data: dataRooms, nextPageToken, isFirstPage }) => {
      dataRooms.length < 8 ? setNextPage(null) : setNextPage(nextPageToken)

      isFirstPage !== false ? setRooms(() => dataRooms) : setRooms((prev) => [...prev, ...dataRooms])

      if (dataRooms.length && history.location.state) {
        setSelectedChat(() => history.location.state)
      }
    },
    [history]
  )

  const handleNewRoom = useCallback(
    (dataRoom) => {

      setRooms((prev) => [dataRoom, ...prev])

      // if (dataRoom.length && history.location.state) {
      //   setSelectedChat(() => history.location.state)
      // }
      getRoomsCount()
    },


    []
  )

  const getAllRooms = useCallback(() => {
    // if (status === "offline") return
    socketRef.current?.emit("rooms:fetch")
  }, [])

  const handleUpdateRoom = useCallback((newRoom) => {
    setRooms((prev) => {
      if (prev.find(room => room.id === newRoom?.id)) {
        return prev.map((room) => {
          if (room.id === newRoom?.id) return newRoom
          else return room
        });
      } else {
        return [newRoom, ...prev]
      }
    }
    );
    getRoomsCount()
  }, [])

  const getAllMessagesByRoom = useCallback((roomId) => {
    socketRef.current?.emit("messages:fetch", roomId)
  }, [])

  const requestLead = useCallback((to) => {
    socketRef.current?.emit("admin:requestLead", to)
  }, [])

  const seenMessage = useCallback((ids) => {
    socketRef.current?.emit("messages:seen", ids)
  }, [])

  const handleTyping = useCallback((roomId, typing) => {
    setTyping((prev) => ({ ...prev, [roomId]: typing }))
  }, [])

  const isTyping = useCallback((roomName) => {
    socketRef.current?.emit("admin:isTyping", roomName, true)
  }, [])

  const stopIsTyping = useCallback((roomName) => {
    socketRef.current?.emit("admin:isTyping", roomName, false)
  }, [])

  const handleRemoveRoom = useCallback(({ roomId, responsibleId }) => {



    setRooms((prevRooms) => {
      const updatedRooms = prevRooms.filter((room) => room.id !== roomId || responsibleId === user?.id)

      return updatedRooms
    })


    setSelectedChat(() => {

      return null
    })

    getRoomsCount()
  }, [])

  const handleRoomsCount = useCallback(({ allRoomsCount, unreadRoomsCount }) => {

    setRoomsCount(+allRoomsCount || 0)
    setUnreadRoomsCount(+unreadRoomsCount || 0)


  }, [])


  const handleStatusFetch = useCallback((onlineStatus) => {
    setStatus(onlineStatus === true ? "online" : "offline")
    if (!onlineStatus) {
      history.replace(history.location.pathname, null)
      setSelectedChat(() => null)
    }
  }, [history])
  useEffect(() => {
    socketRef.current?.emit("status:check")
    getRoomsCount()
  }, [])




  useEffect(() => {
    socketRef.current = socket

    // Event listeners on

    socketRef.current?.on("messages:response", handleGetMessages)
    socketRef.current?.on("rooms:response", handleGetRooms)
    socketRef.current?.on("room:add", handleNewRoom)
    socketRef.current?.on("room:update", handleUpdateRoom)
    // socketRef.current?.on("admin:newUser", newUserJoined)
    socketRef.current?.on("message:get", handleNewMessage)
    socketRef.current?.on("message:update", handleUpdateMessage)
    socketRef.current?.on("isTyping", handleTyping)
    socketRef.current?.on("room:remove", handleRemoveRoom)
    socketRef.current?.on("status:check-res", handleStatusFetch)
    socketRef.current?.on("rooms:count-res", handleRoomsCount)



    return () => {
      // Event listeners off
      socketRef.current?.off("messages:response", handleGetMessages)
      socketRef.current?.off("rooms:response", handleGetRooms)
      socketRef.current?.off("room:add", handleNewRoom)
      socketRef.current?.off("room:update", handleUpdateRoom)
      // socketRef.current?.off("admin:newUser", newUserJoined)
      socketRef.current?.off("message:get", handleNewMessage)
      socketRef.current?.off("message:update", handleUpdateMessage)
      socketRef.current?.off("isTyping", handleTyping)
      socketRef.current?.off("room:remove", handleRemoveRoom)
      socketRef.current?.off("status:check-res", handleStatusFetch)
      socketRef.current?.off("rooms:count-res", handleRoomsCount)

    }
  }, [
    socket,
    handleGetMessages,
    handleGetRooms,
    // newUserJoined,
    getAllRooms,
    selectedChat,
    getAllMessagesByRoom,
    handleNewMessage,
    handleUpdateMessage,
    handleUpdateRoom,
    handleTyping,
    handleRemoveRoom,
    handleStatusFetch,
    handleNewRoom,
    handleRoomsCount
  ])

  useEffect(() => {
    if (selectedChat) {
      socketRef.current?.off("message:get", handleNewMessage)
      socketRef.current?.on("message:get", handleNewMessage)
    }
  }, [selectedChat, handleNewMessage])

  const data = useMemo(
    () => ({
      messages: messages?.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      ),
      sendMessage,
      setUserStatus,
      status,
      getAllMessagesByRoom,
      rooms,
      // : rooms?.sort(
      //   (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
      // ),
      getAllRooms,
      getRoomById,
      getStatus,
      requestLead,
      seenMessage,
      setSelectedChat,
      selectedChat,
      isTyping,
      stopIsTyping,
      typing,
      loadNextPage,
      hasNextPage: nextPage !== null,
      getRoomsCount,
      roomsCount,
      unreadRoomsCount
    }),
    [
      getAllMessagesByRoom,
      getAllRooms,
      getRoomById,
      getStatus,
      sendMessage,
      setUserStatus,
      status,
      messages,
      rooms,
      requestLead,
      seenMessage,
      selectedChat,
      isTyping,
      stopIsTyping,
      typing,
      loadNextPage,
      nextPage,
      getRoomsCount,
      roomsCount,
      unreadRoomsCount
    ]
  )



  return data
}

export default useChat
