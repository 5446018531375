import {
  ApiCallGetMethod,
  ApiCallDeleteMethod,
  ApiCallUploadMethodFormData,
  ApiCallUploadMethodJson,
} from "../../constant/base";

export const getRealTestDrives = () => {
  return ApiCallGetMethod("api/dealerweb/realtestdrives");
};

export const postRealTestDrives = async (formikValues) => {
  const formData = await new FormData();
  formData.append("frk_vehicle_id", +formikValues.vehicle_id);
  formData.append("user_salutation", formikValues.user_salutation);
  formData.append(
    "testDrive_start_date",
    `${formikValues.testDrive_start_date} ${formikValues.testDrive_start_time}`
  );
  formData.append(
    "testDrive_finish_date",
    `${formikValues.testDrive_finish_date} ${formikValues.testDrive_finish_time}`
  );
  formData.append("driverLicenseImage", formikValues.driverLicenseImage);
  formData.append("method_of_contact", formikValues.method_of_contact);
  formData.append("requested_date", formikValues.requested_date);
  formData.append("comment", formikValues.comment);
  formData.append("frk_customer_user_id", formikValues.user);
  // for (var pair of formData.entries()) {
  // console.log(pair[0] + ", " + pair[1]);
  // }
  return ApiCallUploadMethodFormData(
    "api/dealerweb/realtestdrive/add",
    "POST",
    formData
  );
};
export const updateRealTestDrives = async (body) => {
  const { id, value } = body;
  const formData = await new FormData();
  formData.append("frk_vehicle_id", +value.vehicle_id);
  formData.append("user_salutation", value.user_salutation);
  formData.append(
    "testDrive_start_date",
    `${value.testDrive_start_date} ${value.testDrive_start_time}`
  );
  formData.append(
    "testDrive_finish_date",
    `${value.testDrive_finish_date} ${value.testDrive_finish_time}`
  );
  if (value.driverLicenseImage !== undefined) {
    formData.append("driverLicenseImage", value.driverLicenseImage);
  }
  // formData.append("driverLicenseImage", value.driverLicenseImage);

  formData.append("method_of_contact", value.method_of_contact);
  formData.append(
    "requested_date",
    `${value.requested_date} ${value.requested_time}`
  );
  // formData.append("comment", value.comment);
  // for (var pair of formData.entries()) {
  //   console.log(pair[0] + ", " + pair[1]);
  // }

  return ApiCallUploadMethodFormData(
    `api/dealerweb/realtestdrive/update/${id}`,
    "PATCH",
    formData
  );
};

export const deleteRealTestDrive = (id) => {
  return ApiCallDeleteMethod(`api/dealerweb/realtestdrive/delete/${id}`);
};

export const realTestDriveCustomerSignUp = async (value) => {
  const formData = await new FormData();
  formData.append("f_name", value.f_name);
  formData.append("l_name", value.l_name);
  formData.append("email", value.email);
  if (value.mobile && value.mobile !== null && value.mobile !== "") {
    formData.append("mobile", value.mobile);
  }
  if (value.city && value.city !== null && value.city !== "") {
    formData.append("city", value.city ? value.city : "");
  }
  if (value.province && value.province !== null && value.province !== "") {
    formData.append("province", value.province.value);
  }
  if (value.address && value.address !== null && value.address !== "") {
    formData.append("address", value.address);
  }
  if (value.gender && value.gender !== null && value.gender !== "") {
    formData.append("gender", value.gender);
  }
  if (value.birthdate && value.birthdate !== null && value.birthdate !== "") {
    formData.append("birthdate", value.birthdate);
  }
  if (value.frk_city?.value && value.frk_city?.value !== null && value.frk_city?.value !== "" && value.frk_city?.value !== undefined) {
    formData.append("frk_city", value.frk_city?.value);
  }
  if (
    value.postalcode &&
    value.postalcode !== null &&
    value.postalcode !== ""
  ) {
    formData.append("postalcode", value.postalcode);
  }
  if (
    value.aparteman_no &&
    value.aparteman_no !== null &&
    value.aparteman_no !== ""
  ) {
    formData.append("aparteman_no", value.aparteman_no);
  }
  formData.append("driver_license_NO", value.driver_license_NO);
  formData.append(
    "driver_license_expire_date",
    value.driver_license_expire_date
  );
  formData.append(
    "driver_license_issued_date",
    value.driver_license_issue_date
  );
  formData.append("driverLicenseImage", value.driver_license_photo);
  if (value.bcid && value.bcid !== null) {
    formData.append("bcid", value.bcid);
  }
  if (value.customer_authentication_method && value.customer_authentication_method !== null) {
    formData.append("customer_authentication_method", value.customer_authentication_method?.value);
  }
  return ApiCallUploadMethodFormData(
    "app/v2/dealership/real/test/drive/signup",
    "POST",
    formData
  );
  // return ApiCallUploadMethodJson(
  //   "api/dealership/real/test/drive/signup",
  //   "POST",
  //   value
  // );
};
export const sendRealTestDriveConfirmCustomerMobile = (id) => {
  return ApiCallUploadMethodJson(
    `api/dealership/send/code/mobile/${id}`,
    "PATCH",
    {},
    true,
    "Confirm code send to customer"
  );
};
export const sendRealTestDriveConfirmCustomerEmail = (id) => {
  return ApiCallUploadMethodJson(
    `api/dealership/send/code/email/${id}`,
    "PATCH",
    {},
    true,
    "Confirm code send to customer"
  );
};
export const postSendMobileConfirmCodeRealTestDrive = (body) => {
  const { userId, confirmCode } = body;
  return ApiCallGetMethod(
    `api/user/mobile/verification/${userId}/${confirmCode}`
  );
};
export const postSendEmailConfirmCodeRealTestDrive = (body) => {
  const { userId, confirmCode } = body;
  return ApiCallGetMethod(
    `api/user/email/verification/${userId}/${confirmCode}`
  );
};
export const getRealTestDriveDetail = (id) => {
  return ApiCallGetMethod(`api/dealerweb/realtestdrive/get/${id}`);
};
export const getRealTestDriveVehicles = () => {
  return ApiCallGetMethod("api/dealerweb/realtestdrive/vehicles");
};


export const addNoteForTestDrive = (body) => {
  return ApiCallUploadMethodJson(
    `api/dealerweb/realTestDrive/note/add`,
    "POST",
    body
  );
};


export const deleteNoteForTestDrive = (id) => {
  return ApiCallDeleteMethod(
    `api/dealerweb/realTestDrive/note/delete/${id}`,
    "DElETE",

  );
};
export const editNoteForTestDrive = (body, id) => {
  console.log(id)
  return ApiCallUploadMethodJson(
    `api/dealerweb/realTestDrive/note/update/${body.id}`,
    "PATCH",
    body
  );
};