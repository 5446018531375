import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/main.scss";
import "react-toastify/dist/ReactToastify.css";
import "react-table-v6/react-table.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "react-tabs/style/react-tabs.css";
import "react-sortable-tree/style.css";
import "react-image-gallery/styles/css/image-gallery.css";
// import "react-grid-layout/css/styles.css";
// import "react-resizable/css/styles.css";

// import logger from "redux-logger";
// import { createStore, applyMiddleware } from "redux";
// import rootReducer from "./redux/reducers/root_reducer";
// import { Provider } from "react-redux";
import AuthProvider from "./context/auth";
import UesrInfoProvider from "./context/user";
import ErrorBoundaryComp from "./components/common/error/erro_boundary_comp";
import RootErrorBoundryFallback from "./components/common/error/root_error_boundry_fallback";
import { CookiesProvider } from "react-cookie";

// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
// import { Integrations } from "@sentry/tracing";

// Sentry.init({
//   dsn: "https://57743d3ae0114447b849927074d479be@o1299915.ingest.sentry.io/6533473",
//   integrations: [new Integrations.BrowserTracing()],
//   debug: true,

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });
// Sentry.init({
//   dsn: "https://b0ebc4b8fe1a4930ad5e8879b31f0bc8@o1301487.ingest.sentry.io/6538485",
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
// });

ReactDOM.render(
  <ErrorBoundaryComp Fallback={RootErrorBoundryFallback}>
    <UesrInfoProvider>
      <AuthProvider>
        {/* <InventoryVehicle> */}
        {/* <DealInventoryVehicle> */}
        {/* <React.StrictMode> */}
        <CookiesProvider>
          <App />
        </CookiesProvider>
        {/* </React.StrictMode> */}
        {/* </DealInventoryVehicle> */}
        {/* </InventoryVehicle> */}
      </AuthProvider>
    </UesrInfoProvider>
  </ErrorBoundaryComp>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
