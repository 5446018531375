import { useFormik } from 'formik';
import React, { useEffect } from 'react'
import { FaDownload, FaTrash } from 'react-icons/fa';
import SimpleLoading from '../../../common/loading/simple_loading';
import { useMutation, useQuery } from 'react-query';
import { addGallery, galleryImgEdit, gallerySingleGet } from '../../../../utils/user-profile/gallery';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useIsAuthState } from '../../../../hooks/useAuth';
import moment from 'moment-timezone';

const EditImage = ({ isDisabled }) => {
    const momentTimezone = require("moment-timezone");
    const auth = useIsAuthState();
    const { id } = useParams();
    const history = useHistory();
    const getgallerySingleGet = useQuery(
        ["getgallerySingleGet", id],
        () => gallerySingleGet(id),
    );
    const galleryImgEditQuery = useMutation(
        ["galleryImgEditQuery"],
        galleryImgEdit,
        {
            refetchOnMount: false,
            onSuccess: () => {
                history.push(
                    `/admin/gallery`
                );
            },
        }
    );

    const formik = useFormik({
        initialValues: {
            title: getgallerySingleGet?.data?.data?.title || "",
            description: getgallerySingleGet?.data?.data?.description || "",
            visible: getgallerySingleGet?.data?.data?.visible || "false",
            images: [],
            showUntil: "",
            showFrom: "",
        },
        onSubmit: (value, { resetForm }) => {
            galleryImgEditQuery.mutate({ value, id });
            resetForm();
        },
        enableReinitialize: true,

    });
    const handleImageChange = (e) => {
        if (typeof e !== "undefined" && e.target.files.length !== 0) {
            const filesArray = Array.from(e.target.files);
            formik.setFieldValue("images", filesArray);
        }
    };
    return (
        <div className={`p-0 m-0 mt-2 ${isDisabled && "disabled_button_pages"}`}>
            <div className="p-md-3 px-md-4 p-sm-2 p-1 m-0 first_page-container d-flex row align-items-scratch justify-content-start">
                <div className="p-0 m-0 mb-3 col-12">
                    <div className="p-0 m-0 w-100 row justify-content-between">
                        <h2 className="mx-2 col-9">Dealer Gallery</h2>
                    </div>
                </div>
                <div className="p-0 m-0 mt-2 mb-5  bg-white shadow rounded negotiate_inv_filter_section-container">
                    <div className="p-0 m-0 py-3 w-100 row">
                        <form
                            onSubmit={formik.handleSubmit}
                            className="p-0 m-0 d-flex col-12 row align-items-start justify-content-between h-100 p-2"
                        >
                            <h4 className="inner_header mx-1 mb-3">New Image</h4>
                            <div className="p-0 m-0 w-100 row">
                                <div className="p-0 m-0 mb-2 col-12 form-group">
                                    {/* <label>Title</label> */}
                                    <div className="p-0 m-0 d-flex">
                                        <label
                                            className="form-check-label px-2"
                                            htmlFor="visible"
                                        >
                                            Inactive
                                        </label>
                                        <div className="form-check form-switch">
                                            <input
                                                checked={formik?.values?.visible != "false"}
                                                className="form-check-input"
                                                type="checkbox"
                                                id="visible"
                                                name="visible"
                                                onBlur={formik.handleBlur}
                                                onChange={() => {
                                                    if (formik?.values?.visible != "false") {
                                                        formik.setFieldValue("visible", "false");
                                                    } else {
                                                        formik.setFieldValue("visible", "true");
                                                    }
                                                }}
                                            />
                                            <label className="form-check-label" htmlFor="visible">
                                                Active
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div className="p-0 m-0 mb-2 col-12 form-group">
                                    <label>Title</label>
                                    <input
                                        type="text"
                                        name="title"
                                        id="title"
                                        className="form-control rounded rounded-pill form_input-style mt-1 "
                                        placeholder="Title"
                                        value={formik.values.title}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                {/* <div className="col-12 col-md-6 col-lg-6 form-group my-4">
                                    <label>Start Date</label>
                                    <input
                                        type="datetime-local"
                                        name="showFrom"
                                        id="showFrom"
                                        className="form-control rounded rounded-pill form_input-style mt-1"
                                        value={moment.tz(formik.values.showFrom, auth?.dealershipInfo?.business_city?.timezone)
                                            .format('YYYY-MM-DDTHH:mm')}
                                        onChange={(e) => {
                                            const dateInTimeZone = moment.tz(e.target.value, auth?.dealershipInfo?.business_city?.timezone).format();
                                            formik.setFieldValue("showFrom", dateInTimeZone)
                                        }}
                                        onBlur={formik.handleBlur}
                                        min="1970-01-01T00:00"
                                        max="9999-01-01T23:59"
                                    />
                                    {formik.errors.showFrom && formik.touched.showFrom && (
                                        <div className="text-danger">{formik.errors.showFrom}</div>
                                    )}
                                </div>
                                <div className="col-12 col-md-6 col-lg-6 form-group my-4">
                                    <label>End Date</label>
                                    <input
                                        type="datetime-local"
                                        name="showUntil"
                                        id="showUntil"
                                        className="form-control rounded rounded-pill form_input-style mt-1"
                                        value={moment.tz(formik.values.showUntil, auth?.dealershipInfo?.business_city?.timezone)
                                            .format('YYYY-MM-DDTHH:mm')}
                                        onChange={(e) => {
                                            const dateInTimeZone = moment.tz(e.target.value, auth?.dealershipInfo?.business_city?.timezone).format();
                                            formik.setFieldValue("showUntil", dateInTimeZone)
                                        }}
                                        onBlur={formik.handleBlur}
                                        min="1970-01-01T00:00"
                                        max="9999-01-01T23:59"
                                    />
                                    {formik.errors.showUntil && formik.touched.showUntil && (
                                        <div className="text-danger">{formik.errors.showUntil}</div>
                                    )}
                                </div> */}
                                <div className="p-0 m-0 mb-2 mt-2 col-12 form-group">
                                    <label>Description</label>
                                    <textarea
                                        name="description"
                                        id="description"
                                        cols="30"
                                        rows="3"
                                        className="form-control form_textarea-style "
                                        placeholder="Type description"
                                        value={formik.values.description}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                                <div className="p-0 m-0 mb-2 mt-2 col-12 form-group">
                                    <label
                                        className="btn window_sticker-btn px-5"
                                        htmlFor="images"
                                    >
                                        Choose Attachment
                                    </label>
                                    <input
                                        type="file"
                                        name="images"
                                        id="images"
                                        className="d-none"
                                        onChange={handleImageChange}
                                        onBlur={formik.handleBlur}
                                    />
                                    {formik.values.images.map((images, index) => {
                                        return images !== null &&
                                            images !== "" &&
                                            images?.type !== "application/pdf" ? (
                                            <div className="form-group w-100 p-0 my-2">
                                                <img
                                                    src={images && URL.createObjectURL(images)}
                                                    alt={images}
                                                    style={{ width: "200px", height: "auto" }}
                                                />
                                                <i
                                                    className="p-0 m-0 trash_icon-style"
                                                    onClick={() => {
                                                        formik.setFieldValue(
                                                            "images",
                                                            formik.values.images.filter(
                                                                (img) => img.lastModified !== images.lastModified
                                                            )
                                                        );
                                                    }}
                                                >
                                                    <FaTrash />
                                                </i>
                                            </div>
                                        ) : (
                                            images !== null &&
                                            images !== "" &&
                                            images?.type !== "image/png" && (
                                                <div className="form-group w-100 p-0 my-2 trash_icon-style">
                                                    {images?.name}

                                                    <i
                                                        className="p-0 m-0 mx-2 trash_icon-style"
                                                        onClick={() => {
                                                            formik.setFieldValue(
                                                                "images",
                                                                formik.values.images.filter(
                                                                    (img) => img.lastModified !== images.lastModified
                                                                )
                                                            );
                                                        }}
                                                    >
                                                        <FaTrash />
                                                    </i>
                                                </div>
                                            )
                                        );
                                    })}
                                </div>
                                {getgallerySingleGet?.data && [getgallerySingleGet?.data?.data]?.length !== 0 &&
                                    [getgallerySingleGet?.data?.data].map((file, index) => {
                                        const found_type2 = file?.media_src.match(/\.\w{3,4}$/);
                                        let typeFilees = "image/png";
                                        if (
                                            found_type2[0].toLowerCase() === ".webp" ||
                                            found_type2[0].toLowerCase() === ".jpg" ||
                                            found_type2[0].toLowerCase() === ".jpg" ||
                                            found_type2[0].toLowerCase() === ".png" ||
                                            found_type2[0].toLowerCase() === ".jpeg"
                                        ) {
                                            typeFilees = "image/png";
                                        } else {
                                            if (found_type2[0] === ".pdf") {
                                                typeFilees = "application/pdf";
                                            }
                                        }
                                        return file?.media_src && formik.values.images !== null ? (
                                            typeFilees !== "image/png" ? (
                                                <div className={`form-group w-100 p-0 my-2 `}>
                                                    <a
                                                        href={`https://hillzimage.blob.core.windows.net${file?.media_src}`}
                                                        download
                                                        className="p-0 m-0 edit_icon-style"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        <FaDownload />
                                                    </a>
                                                    <i
                                                        className="p-0 m-0 trash_icon-style"
                                                        onClick={() => {
                                                            // descriptionDetailsImgDeleteQuery.mutate({ id: file?.id });
                                                        }}
                                                    >
                                                        <FaTrash />
                                                    </i>
                                                </div>
                                            ) : (
                                                <div className={`form-group w-100 p-0 my-2 `}>
                                                    <img
                                                        src={`https://hillzimage.blob.core.windows.net${file?.media_src}`}
                                                        alt={file?.media_src}
                                                        style={{ width: "200px", height: "auto" }}
                                                    />
                                                    {/* <i
                                                        className="p-0 m-0 trash_icon-style"
                                                        onClick={() => {
                                                            formik.setFieldValue("images",
                                                                [getgallerySingleGet?.data?.data].filter(
                                                                    (img) => img.id !== file?.id
                                                                )
                                                            );
                                                        }}
                                                    >
                                                        <FaTrash />
                                                    </i> */}
                                                </div>
                                            )
                                        ) : undefined;
                                    })}

                            </div>
                            <div className="p-0 m-0 w-100" style={{ textAlign: "end" }}>
                                {galleryImgEditQuery.isLoading ? (
                                    <SimpleLoading />
                                ) : (
                                    <button type="submit" className="btn form_submit-btn px-5">
                                        Submit
                                    </button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default EditImage