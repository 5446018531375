import SimpleLoading from "./simple_loading";

const Loading = ({ height = "60vh", padding = "p-5" }) => {
  // const {height:"60vh"} = props
  return (
    <div
      className={`d-flex justify-content-center align-items-center ${padding} `}
      style={{ height: height, }}
    >
      <SimpleLoading />
    </div>
  );
};

export default Loading;
