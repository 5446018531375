import {
  ApiCallDeleteMethod,
  ApiCallGetMethod,
  ApiCallUploadMethodFormData,
} from "../../constant/base";

export const addGallery = async ({ value }) => {
  const formData = await new FormData();
  formData.append("title", value.title);
  formData.append("description", value.description);
  formData.append("showUntil", value.showUntil);
  formData.append("showFrom", value.showFrom);
  if (value.images && value.images?.length !== 0) {
    value.images.map((file) => {
      formData.append("images", file);
    });
  }
  return ApiCallUploadMethodFormData(
    `api/v1/dealership/gallery`,
    "POST",
    formData
  );
};
export const galleryGet = async (id) => {
  return ApiCallGetMethod(`api/v1/dealership/gallery`);
};
export const gallerySingleGet = async (id) => {
  return ApiCallGetMethod(`api/v1/dealership/gallery/${id}`);
};

export const galleryImgEdit = async ({ value, id }) => {
  const formData = await new FormData();
  value.images &&
    value.images.map((file) => {
      formData.append("image", file);
    });
  value?.title && formData.append("title", value?.title);
  value?.description && formData.append("description", value?.description);
  value?.showUntil && formData.append("showUntil", value.showUntil);
  value?.showFrom && formData.append("showFrom", value.showFrom);
  value?.visible && formData.append("visible", value.visible);

  return ApiCallUploadMethodFormData(
    `api/v1/dealership/gallery/${id}`,
    "PATCH",
    formData
  );
};
export const galleryImgDelete = async ({ value, id }) => {
  return ApiCallDeleteMethod(`api/v1/dealership/gallery/${id}`, "DELETE");
};
