import React, { useMemo, useState, createContext } from "react";
import { io } from "socket.io-client";
import useChat from "../hooks/useChat";
// import { BASE_URL } from "../constant/base"

// const socketIo = io(`ws://192.168.1.194:5000/chat`, {
const socketIo = io(`wss://chat.hillzusers.com:8443/chat`, {
  transports: ["websocket"],
  autoConnect: false,
  withCredentials: true,
  secure: true,
  reconnectionAttempts: 10,
  forceNew: false,
});

export const SocketContext = createContext({
  socket: socketIo,
  setSocket: () => {},
});

const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(socketIo);
  const [status, setStatus] = useState("offline");
  const [history, setHistory] = useState(null);
  const chat = useChat(socket, status, setStatus, history);

  const value = useMemo(() => ({ socket, setSocket }), [socket]);
  const statusValue = useMemo(() => ({ status, setStatus }), [status]);

  return (
    <SocketContext.Provider
      value={{ ...value, ...statusValue, chat, setHistory, history }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default React.memo(SocketProvider);
