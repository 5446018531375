import {
  ApiCallDeleteMethod,
  ApiCallGetMethod,
  BASE_URL,
} from "../../constant/base";
import { Cookies } from "react-cookie";
import { checkRefreshToken } from "../auth/checkRefreshToken";
import { useQuery } from "react-query";
const cook = new Cookies();
export const getAllCustomer = async (query) => {
  const _res = await fetch(
    `${BASE_URL}api/dealership/customers${query === 1 ? "?sorted=1" : ""}`,
    {
      credentials: "include",
      headers: {
        authorization: `Bearer ${cook.get("token")}`,
      },
    }
  );
  if (_res.status === 200) {
    const data = await _res.json();
    // const data = await _resJson.customers;
    return data;
  } else if (_res.status === 404) {
    throw new Error("not found");
  } else if (_res.status === 401) {
    checkRefreshToken();
    throw new Error("unauthorize");
  } else if (_res.status === 400) {
    throw new Error("bad request");
  } else {
    throw new Error("internal error");
  }
};
export const getAllWholeSaleCustomer = async (query) => {
  const _res = await fetch(
    `${BASE_URL}wholesale`,
    {
      credentials: "include",
      headers: {
        authorization: `Bearer ${cook.get("token")}`,
      },
    }
  );
  if (_res.status === 200) {
    const data = await _res.json();
    // const data = await _resJson.customers;
    return data;
  } else if (_res.status === 404) {
    throw new Error("not found");
  } else if (_res.status === 401) {
    checkRefreshToken();
    throw new Error("unauthorize");
  } else if (_res.status === 400) {
    throw new Error("bad request");
  } else {
    throw new Error("internal error");
  }
};
export const useGetAllCustomers = (query = null) => {
  return useQuery("all-customer", () => getAllCustomer(query), {
    refetchOnWindowFocus: false,
    refetchOnMount: true,
  });
};
export const DeleteCustomer = (id) => {
  return ApiCallDeleteMethod(`api/user/remove/customer/${id}`, "DELETE");
};
export const EditCustomer = ({ id, value }) => {
  return ApiCallDeleteMethod(`wholesale/${id}`, "DELETE", value);
};
export const filterCaseInsensitive = (filter, row) => {
  const id = filter.pivotId || filter.id;
  const content = row[id];
  if (typeof content !== "undefined") {
    if (typeof content === "object" && content !== null && content.key) {
      return String(content.key)
        .toLowerCase()
        .includes(filter.value.toLowerCase());
    } else {
      return String(content).toLowerCase().includes(filter.value.toLowerCase());
    }
  }
  return true;
};
export const filterCaseInsensitiveInventory = (filter, row) => {
  const id =
    filter?.id === "vehicle_status" && filter?.value === "3"
      ? "in_service"
      : filter?.id === "vehicle_status" && filter?.value === "8"
      ? "in_transit"
      : filter.pivotId || filter.id;
  const content =
    filter?.id === "vehicle_status" && filter?.value === "3"
      ? row?._original[id]
      : filter?.id === "vehicle_status" && filter?.value === "8"
      ? row?._original[id]
      : row[id];
  if (typeof content !== "undefined") {
    if (filter?.id === "vehicle_status" && filter?.value === "3") {
      if (typeof content === "object" && content !== null && content.key) {
        return String(content.key)
          .toLowerCase()
          .includes(filter.value.toLowerCase());
      } else {
        return content === 1 ? true : false;
      }
    } else {
      if (filter?.id === "vehicle_status" && filter?.value === "8") {
        if (typeof content === "object" && content !== null && content.key) {
          return String(content.key)
            .toLowerCase()
            .includes(filter.value.toLowerCase());
        } else {
          return content === 1 ? true : false;
        }
      } else {
        if (typeof content === "object" && content !== null && content.key) {
          return String(content.key)
            .toLowerCase()
            .includes(filter.value.toLowerCase());
        } else {
          return String(content)
            .toLowerCase()
            .includes(filter.value.toLowerCase());
        }
      }
    }
  }
  return true;
};
export const filterCaseInsensitiveForLeads = (filter, row) => {
  const id = filter.pivotId || filter.id;
  const content = row[id];
  if (typeof content !== "undefined") {
    if (typeof content === "object" && content !== null && content.key) {
      return String(content.key)
        .toLowerCase()
        .includes(filter.value.toLowerCase());
    } else {
      if (filter.id === "createdAt" || filter.id === "sellerFullName") {
        return String(content)
          .toLowerCase()
          .includes(filter.value.toLowerCase());
      } else {
        return filter.value === 0
          ? true
          : content === filter.value
          ? true
          : false;
      }
    }
  }
  return true;
};

export const handleClose = (setShow) => {
  return setShow(false);
};
export const handleShow = (setShow) => {
  return setShow(true);
};

//filter Customer//
export const FilterCustomer = async () => {
  return ApiCallGetMethod("app/dealership/customer/getAllMinimal");
};
export const BcIdCustomer = async () => {
  return ApiCallGetMethod("customer/authentication/methods");
};
export const getAllSalesManagement = async () => {
  return ApiCallGetMethod("sales/managers");
};
export const getAllSalesManagementPurchaser = async () => {
  return ApiCallGetMethod("sales/managers?roles=3");
};
