import React, { memo, useContext, useEffect } from "react";
// import { OverlayTrigger, Popover } from "react-bootstrap"
import { NavLink, useHistory } from "react-router-dom";
import {
  FaCar,
  FaEnvelopeOpenText,
  FaTasks,
  FaPenNib,
  FaHandshake,
  FaUsers,
  FaUsersCog,
  FaWrench,
  FaRoad,
  FaBullhorn,
  FaTachometerAlt,
  FaDollarSign,
  FaCommentAlt,
  FaCalculator,
} from "react-icons/fa";
import { useDrawerSetter, useDrawerState } from "../../hooks/useDrawer";
import HillzLogoIcon from "../common/svgs/hillz_logo_svg";
import { SocketContext } from "../../context/socket";
import { useCookies } from "react-cookie";
import { useIsAuthState } from "../../hooks/useAuth";

const OperatoreDrawer = () => {
  const drawer = useDrawerState();
  const setDrawerState = useDrawerSetter();
  const { socket, setSocket } = useContext(SocketContext);
  const [{ token }] = useCookies(["token"]);
  const user = useIsAuthState().user;
  const history = useHistory();

  useEffect(() => {
    if (!socket.connected && token) {
      setSocket((prevSocket) => {
        prevSocket.auth = { isPanel: true, token, isWeb: true };
        const newSocket = prevSocket.connect();

        newSocket.on("message:get", (newMessage) => {
          if (Notification.permission === "granted") {
            const room = newMessage.ChatRoom;
            const title = room.creator.is_guest
              ? room.creator_ip
              : room.creator.username;

            const icon = newMessage?.ChatRoom?.creator?.avatar
              ? `https://hillzimage.blob.core.windows.net${newMessage.ChatRoom.creator.avatar}`
              : undefined;

            const notification = new Notification(title, {
              body: newMessage.message,
              icon,
            });

            notification.addEventListener("click", () => {
              window.focus();
              notification.close();
              history.push("/admin/chat", newMessage.roomId);
            });
          }
        });
        return newSocket;
      });

      setSocket((prevSocket) => prevSocket.connect());
    }

    // return () => setSocket(() => socket.disconnect());
  }, [history, setSocket, socket, token]);

  return (
    <div
      className={`d-flex flex-column justify-content-start align-items-start sidenav-container ${
        drawer ? "sidenavOpen" : "sidenavClose"
      } `}
      onMouseOver={() => setDrawerState(true)}
      onMouseLeave={() => setDrawerState(false)}
    >
      <div className="p-2 m-0 pt-1 mb-5 sidebar_ul d-flex flex-column justify-content-center align-items-start">
      <NavLink
          to="/admin"
          className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start "
          activeClassName="sidebar_navlink"
          onClick={() => {
            setDrawerState(false);
            localStorage.setItem("statusFilter", 1);
            localStorage.setItem("setPage", 0);
          }}
        >
          <FaTachometerAlt className="sidebar_icons" />
          <p
            className={`${drawer ? "" : "d-none"} p-0 m-0`}
            style={{ fontSize: "1.1rem" }}
          >
            DASHBOARD
          </p>
        </NavLink>
        <NavLink
          to="/admin/vehicle-managment"
          className="p-1 px-2 text-decoration-none sidebar_li d-flex justify-content-start align-items-start "
          // activeClassName="sidebar_navlink"
          onClick={() => {
            setDrawerState(false);
          }}
        >
          <FaCar className="sidebar_icons" />
          <p
            className={`${drawer ? "" : "d-none"} p-0 m-0`}
            style={{ fontSize: "1.1rem" }}
          >
            INVENTORY
          </p>
        </NavLink>
      </div>
    </div>
  );
};

export default memo(OperatoreDrawer);
