/* eslint-disable array-callback-return */
import moment from "moment";

export const SIGNIN_INITILVALUES = {
  username: "",
  password: "",
};
export const DEALER_REAL_TEST_DRIVE_INITVALUES = {
  frk_vehicle_id: "",
  user_salutation: "",
  testDrive_start_date: "",
  testDrive_start_time: "",
  testDrive_finish_date: "",
  testDrive_finish_time: "",
  driverLicenseImage: null,
  method_of_contact: "",
  requested_date: "",
  requested_time: "",
  comment: "",
  status: 0,
};
export const PRICING_INITIAL_VALUES = {
  ssl_first_name: "",
  ssl_last_name: "",
  ssl_exp_date: "",
  ssl_cvv2cvc2: "",
  month: "",
  year: "",
  number: "",
};
export const SELLER_ADD_CUSTOMER = {
  f_name: "",
  l_name: "",
  email: "",
  mobile: "",
};
export const BILL_CALCULATOR_INITIAL_VALUES = (sale_price = null, cache) => {
  return {
    price_sales_price: cache["price_sales_price"] || sale_price || "",
    price_extended_warranty: cache["price_extended_warranty"] || "",
    price_omvic_fee: cache["price_omvic_fee"] || "",
    price_safety: cache["price_safety"] || "",
    price_finance_fee: cache["price_finance_fee"] || "",
    price_total_vehicle_price: cache["price_total_vehicle_price"] || "",
    price_total_vehicle_price_less_trade_in:
      cache["price_total_vehicle_price_less_trade_in"] || "",
    price_less_trade_in_aloowance: cache["price_less_trade_in_aloowance"] || "",
    price_hst_on_total_vehicle_price_less_trade_in:
      cache["price_hst_on_total_vehicle_price_less_trade_in"] || "",
    price_licence_fee: cache["price_licence_fee"] || "",
    price_gasoline: cache["price_gasoline"] || "",
    price_payout_lien_against_trade_in:
      cache["price_payout_lien_against_trade_in"] || "",
    price_total_purchase_price: cache["price_total_purchase_price"] || "",
    price_payable_on_delivery: cache["price_payable_on_delivery"] || "",
    price_life_insurance: cache["price_life_insurance"] || "",
    price_loss_of_income_insurance:
      cache["price_loss_of_income_insurance"] || "",
    price_rst_on_insurance: cache["price_rst_on_insurance"] || "",
    price_accident_and_health_insurance:
      cache["price_accident_and_health_insurance"] || "",
    price_lien_registration_and_admin_fee:
      cache["price_lien_registration_and_admin_fee"] || "",
    // price_cost_of_borrowing: cache["price_cost_of_borrowing"] || "",
    // price_balance_owing: cache["price_balance_owing"] || "",
    downPayment: cache["downPayment"] || "",
    tradeValue: cache["tradeValue"] || "",
    intRate: cache["intRate"] || "",
    loanTerm: cache["loanTerm"] || "",
    //prices
  };
};
// export const FINANCIAL_CALCULATOR_INITIAL_VALUES = (
//   sale_price = null,
//   cache
// ) => {
//   return {
//     vehiclePrice: cache["vehiclePrice"] || sale_price || "",
//     downPayment: cache["downPayment"] || "",
//     tradeValue: cache["tradeValue"] || "",
//     intRate: cache["intRate"] || "",
//     loanTerm: cache["loanTerm"] || "",
//   };
// };

export const OFFLINE_CHAT_INITIAL_VALUES = {
  comment: "",
  negotiate_id: "",
  images: [],
};
export const ALL_CARS_FILTERING_INITIAL_VALUES = (isDealership) => {
  if (isDealership) {
    return {
      lead_type: "",
      negotiate_status: "",
      customer_type: "",
      success_forcast: "",
      dealership_comment: "",
      dealership_opinion: "",
      car_on_negotiate_status: "",
      mid_success_forcast: "",
      odometer: "",
      odometer_type: "",
      vehicle_condition: "",
      is_it_special: "",
      sell_price: "",
      special_price: "",
      vehicle_status: "",
      vin_number: "",
      make: "",
      model: "",
      model_year: "",
      body_style: "",
      drive_type: "",
      engine_cylinders: "",
      doors: "",
      engine_type: "",
      horse_power: "",
      engine_size: "",
      trim: "",
      fuel_type: "",
      fuel_capacity: "",
      high_msrp: "",
      email: "",
      mobile: "",
      gender: "",
      f_name: "",
      l_name: "",
      id: "",
      sale_price: "",
      waranty: "",
      financeAmount: "",
      term: "",
      profit: "",
      payments: "",
    };
  } else
    return {
      lead_type: "",
      negotiate_status: "",
      customer_type: "",
      success_forcast: "",
      dealership_comment: "",
      dealership_opinion: "",
      car_on_negotiate_status: "",
      mid_success_forcast: "",
      odometer: "",
      odometer_type: "",
      vehicle_condition: "",
      is_it_special: "",
      sell_price: "",
      special_price: "",
      vehicle_status: "",
      vin_number: "",
      make: "",
      model: "",
      model_year: "",
      body_style: "",
      drive_type: "",
      engine_cylinders: "",
      doors: "",
      engine_type: "",
      horse_power: "",
      engine_size: "",
      trim: "",
      fuel_type: "",
      fuel_capacity: "",
      high_msrp: "",
      email: "",
      mobile: "",
      gender: "",
      f_name: "",
      l_name: "",
      sale_price: "",
      waranty: "",
      financeAmount: "",
      term: "",
      profit: "",
      payments: "",
    };
};
export const FINANCIAL_CALC_BILL_OF_SALE = (fields, data) => {
  if (typeof data !== "undefined" && data !== null) {
    const initialValue = {};
    fields?.map((field, index) => {
      initialValue[field.part_name] = data[field.part_name];
    });
    return initialValue;
  } else {
    return {};
  }
};
export const RETURNED_TAX_PRICE_DEAL_PREV_STATUS = (data) => {
  const taxes = data?.taxes ? Object.entries(data?.taxes) : [];
  // if (typeof data !== "undefined") {
  const initialValue = {
    return_price: data?.price,
  };
  taxes?.map((field) => {
    initialValue[field[0]] = field[1];
  });
  return initialValue;
  // } else {
  //   return {};
  // }
};
export const SERVICE_DETAIL_INIT_VALUE = (data) => {
  if (typeof data !== "undefined") {
    const initialValue = {};
    data?.map((categoty) => {
      categoty?.ServiceProblemNames?.map((serviceName) => {
        if (serviceName?.serviceDetail === null) {
          if (
            categoty?.cat_name === "Transmission" &&
            serviceName?.problem_name === "Fluid Level"
          ) {
            initialValue["FluidLevell"] = {
              vendor_id: "",
              SPN_id: "",
            };
          } else {
            if (
              categoty?.cat_name === "Brake System" &&
              serviceName?.problem_name === "A.B.S*"
            ) {
              initialValue["ABS*"] = {
                vendor_id: "",
                SPN_id: "",
              };
            } else {
              initialValue[serviceName?.problem_name?.replace(/ /g, "")] = {
                vendor_id: "",
                SPN_id: "",
              };
            }
          }
        } else {
          if (
            categoty?.cat_name === "Transmission" &&
            serviceName?.problem_name === "Fluid Level"
          ) {
            initialValue["FluidLevell"] = {
              vendor_id: serviceName?.serviceDetail?.frk_vendor_id,
              SPN_id: serviceName?.id,
            };
          } else {
            initialValue[serviceName?.problem_name?.replace(/ /g, "")] = {
              vendor_id: serviceName?.serviceDetail?.frk_vendor_id,
              SPN_id: serviceName?.id,
            };
          }
        }
      });
    });
    return initialValue;
  } else {
    return {};
  }
};
export const SINGLE_VEHICLE_BILL_OF_SALE_CALC = (
  data = [],
  ColorsOptions = [],
  bosType
) => {
  let initialValues = {};

  data?.filter((field) => {
    initialValues[field?.part_name] = field?.value ? field?.value : null;
    if (initialValues["trade_in_colour"]) {
      if (field?.part_name === "trade_in_colour") {
        initialValues["trade_in_colour"] = ColorsOptions?.filter(
          (t) => t?.label === field?.value
        )[0];
      }
    }
    if (bosType === 1) {
      initialValues["price_lien_registration_and_admin_fee"] = "";
      initialValues["price_total_to_be_financed"] = "";
      initialValues["financing_interest_rate"] = "";
      initialValues["price_cost_of_borrowing"] = "";
      initialValues["price_balance_finances_approval"] = "";
      // initialValues["balance_owing"] = 0;
      initialValues["amount_to_finance"] = "";
      initialValues["ppsa_fee"] = "";
      // initialValues["extra_fee_2"] = "";
      initialValues["financing_payments_term"] = "";
      initialValues["cost_of_borrowing_rate"] = "";
      initialValues["price_to_be_financed"] = "";
      initialValues["payment_type"] = "";
    }
    if (bosType === 2 || bosType === 3) {
      if (
        field?.part_name === "financing_total_payment" &&
        initialValues["financing_total_payment"]
      ) {
        initialValues["financing_total_payment"] = field?.value;
      }
      if (
        field?.part_name === "price_cost_of_borrowing" &&
        initialValues["price_cost_of_borrowing"]
      ) {
        initialValues["price_cost_of_borrowing"] = field?.value;
      }
      if (
        field?.part_name === "financing_total_payment" &&
        initialValues["financing_total_payment"]
      ) {
        initialValues["financing_total_payment_back_val"] = field?.value;
      }
      if (
        field?.part_name === "financing_payments" &&
        initialValues["financing_payments"]
      ) {
        initialValues["financing_payments_back_val"] = field?.value;
      }
      if (
        field?.part_name === "price_cost_of_borrowing" &&
        initialValues["price_cost_of_borrowing"]
      ) {
        initialValues["price_cost_of_borrowing_back_val"] = field?.value;
      }
    }
    if (
      field?.part_name === "selected_tax_name" &&
      initialValues["selected_tax_name"]
    ) {
      initialValues["tax_selected"] = field?.value;
    }
    if (field?.part_name === "no_tax" && initialValues["no_tax"]) {
      initialValues["no_tax"] = field?.value;
    }
    if (
      field?.part_name === "five_percent_tax" &&
      initialValues["five_percent_tax"]
    ) {
      initialValues["five_percent_tax"] = field?.value;
    }
  });
  return initialValues;
};
export const WIZARDE_VEHICLE_BILL_OF_SALE_CALC = (
  data = [],
  getSingleCreatWizardBillOfSaleQueryData = []
) => {
  let initialValues = {};
  data?.UcdaPreset?.TaxRates.map((field) => {
    initialValues[field?.MidUcdaTaxRates.tax_rate_name] =
      field?.MidUcdaTaxRates.tax_rate_amount;
  });
  if (getSingleCreatWizardBillOfSaleQueryData?.BillOfSale) {
    getSingleCreatWizardBillOfSaleQueryData?.BillOfSale?.BOS_DefinitionValues.map(
      (bill) => {
        if (bill?.part_name === "price_sales_price") {
          initialValues[bill?.part_name] =
            bill?.value !== null
              ? bill?.value
              : getSingleCreatWizardBillOfSaleQueryData?.MidVehicleDealership
              ? getSingleCreatWizardBillOfSaleQueryData?.MidVehicleDealership
                  ?.special_price &&
                getSingleCreatWizardBillOfSaleQueryData?.MidVehicleDealership
                  ?.special_price !== 0
                ? getSingleCreatWizardBillOfSaleQueryData?.MidVehicleDealership
                    ?.special_price
                : getSingleCreatWizardBillOfSaleQueryData?.MidVehicleDealership
                    ?.sell_price
              : 0;
        } else {
          initialValues[bill?.part_name] =
            bill?.value !== null ? bill?.value : 0;
        }
      }
    );

    initialValues["deposit"] = "";
    initialValues["purchase_Price"] = "";
    initialValues["Discount"] = "";
    initialValues["Sub_total"] = "";
    // initialValues["tax_rate"] =
    getSingleCreatWizardBillOfSaleQueryData?.UcdaPreset?.TaxRates?.length !== 0
      ? (initialValues["tax_rate"] =
          getSingleCreatWizardBillOfSaleQueryData?.UcdaPreset?.TaxRates?.map(
            (field) => {
              // initialValues[field?.MidUcdaTaxRates.tax_rate_name] =
              //   field?.MidUcdaTaxRates.tax_rate_amount;

              let label = "";
              let value = "";
              let name = "";
              let rate = "";
              // let id = "";
              label = `${field?.MidUcdaTaxRates?.tax_rate_name} % ${field?.MidUcdaTaxRates?.tax_rate_amount} `;
              value = field?.MidUcdaTaxRates?.TaxRateId;
              name = field?.MidUcdaTaxRates?.tax_rate_name;
              rate = field?.MidUcdaTaxRates.tax_rate_amount;
              // id = field?.MidUcdaTaxRates?.TaxRateId;
              return { label, value, name, rate };
            }
          ))
      : (initialValues["tax_rate"] = []);
    initialValues["total_tax"] = "";
    initialValues["license_fee"] = "";
    initialValues["total_balance"] = "";
    initialValues["tradevalue"] = "";
    initialValues["actualcashvalue"] = getSingleCreatWizardBillOfSaleQueryData
      ?.MidNgtTrdVehicle?.actual_cash_value
      ? getSingleCreatWizardBillOfSaleQueryData?.MidNgtTrdVehicle
          ?.actual_cash_value
      : 0;
    initialValues["trade_equity"] = getSingleCreatWizardBillOfSaleQueryData
      ?.MidNgtTrdVehicle?.trade_equity
      ? getSingleCreatWizardBillOfSaleQueryData?.MidNgtTrdVehicle?.trade_equity
      : 0;
  } else {
    initialValues["deposit"] = "";
    initialValues["purchase_Price"] = "";
    initialValues["Discount"] = "";
    initialValues["Sub_total"] = "";
    initialValues["tax_rate"] = [];
    initialValues["total_tax"] = "";
    initialValues["license_fee"] = "";
    initialValues["total_balance"] = "";
    initialValues["tradevalue"] = "";
    initialValues["actualcashvalue"] = getSingleCreatWizardBillOfSaleQueryData
      ?.MidNgtTrdVehicle?.actual_cash_value
      ? getSingleCreatWizardBillOfSaleQueryData?.MidNgtTrdVehicle
          ?.actual_cash_value
      : 0;
    initialValues["trade_equity"] = getSingleCreatWizardBillOfSaleQueryData
      ?.MidNgtTrdVehicle?.trade_equity
      ? getSingleCreatWizardBillOfSaleQueryData?.MidNgtTrdVehicle?.trade_equity
      : 0;
    /*------------------------------*/
    initialValues["price_balance_due"] = 0;
    initialValues["price_extended_warranty"] = 0;
    initialValues["price_cost_of_borrowing"] = 0;
    initialValues["price_lien_registration_and_admin_fee"] = 0;
    initialValues["price_rst_on_insurance"] = 0;
    initialValues["price_payable_on_delivery"] = 0;
    initialValues["price_deposit"] = 0;
    initialValues["price_deposit_type"] = 0;
    initialValues["price_sub_total_1"] = 0;
    initialValues["price_hst_registrants_sub_total"] = 0;
    initialValues["price_licence_fee"] = 0;
    initialValues["price_less_trade_in_allowance"] =
      getSingleCreatWizardBillOfSaleQueryData?.MidNgtTrdVehicle?.trade_value
        ? getSingleCreatWizardBillOfSaleQueryData?.MidNgtTrdVehicle?.trade_value
        : 0;
    initialValues["price_payout_lien_against_trade_in"] = 0;
    initialValues["price_total_to_be_financed"] = 0;
    initialValues["price_gasoline"] = 0;
    initialValues["price_net_difference"] = 0;
    initialValues["price_hst_non_registrants_difference"] = 0;
    initialValues["price_hst_non_registrants_tradein"] = 0;
    initialValues["price_sub_total_2"] = 0;
    initialValues["price_life_loss_insurance"] = 0;
    initialValues["price_disability_other_insurance"] = 0;
    initialValues["price_balance_finances_approval"] = 0;
    initialValues["price_discount"] = 0;
    initialValues["financing_payment_start_date"] = 0;
    initialValues["financing_payments_term"] = 0;
    initialValues["financing_total_payment"] = 0;
    initialValues["vehicle_delivery_date"] = 0;
    initialValues["price_sales_price"] =
      getSingleCreatWizardBillOfSaleQueryData?.MidVehicleDealership
        ? getSingleCreatWizardBillOfSaleQueryData?.MidVehicleDealership
            ?.special_price &&
          getSingleCreatWizardBillOfSaleQueryData?.MidVehicleDealership
            ?.special_price !== 0
          ? getSingleCreatWizardBillOfSaleQueryData?.MidVehicleDealership
              ?.special_price
          : getSingleCreatWizardBillOfSaleQueryData?.MidVehicleDealership
              ?.sell_price
        : 0;
  }
  return initialValues;
};
export const SINGLE_VEHICLE_RESET_BILL_OF_SALE_CALC = (
  data = [],
  fieldsTax = []
) => {
  let initialValues = {};

  data.map((field) => {
    if (
      data?.part_name === "price_balance_owing" ||
      data?.part_name === "price_deposit_type" ||
      data?.part_name === "cost_of_borrowing_rate"
    ) {
      initialValues[field?.part_name] = null;
    } else {
      initialValues[field?.part_name] = null;
    }
  });

  return initialValues;
};
export const WIZARDE_VEHICLE_BILL_OF_SALE_FEE = (preset, selectedTax = []) => {
  let initialValues = {};
  // data?.UcdaPreset?.Fees?.TaxRates?.map((field) => {
  //   initialValues[field?.MidUcdaTaxRates.tax_rate_name] = field?.MidUcdaTaxRates.tax_rate_amount;
  //   initialValues["tax"] = field?.MidUcdaTaxRates.tax_rate_amount;
  //   initialValues["name"] = field?.MidUcdaTaxRates.tax_rate_name;
  // });
  selectedTax?.map((item) => {
    initialValues[`tax_${item?.id}`] = item.rate;
  });

  // fieldsTax.map((tax) => {
  initialValues["tax_rate"] = selectedTax;
  initialValues["lie_fee"] = 0;
  initialValues["tax"] = 0;
  // });
  return initialValues;
};
export const WIZARDE_VEHICLE_BILL_OF_SALE_ACCESSORY = (
  preset,
  selectedTax = []
) => {
  let initialValues = {};
  // data?.UcdaPreset?.TaxRates.map((field) => {
  //   initialValues[field?.MidUcdaTaxRates.tax_rate_name] = field?.MidUcdaTaxRates.tax_rate_amount;
  // });
  selectedTax?.map((item) => {
    initialValues[`tax_${item?.id}`] = item.rate;
  });
  // fieldsTax.map((tax) => {
  initialValues["tax_rate"] = selectedTax;
  initialValues["tax"] = 0;
  // });
  return initialValues;
};
export const WIZARDE_VEHICLE_BILL_OF_SALE_WARANTY = (
  preset,
  selectedTax = []
) => {
  let initialValues = {};
  selectedTax?.map((item) => {
    initialValues[`tax_${item?.id}`] = item.rate;
  });
  // fieldsTax.map((tax) => {
  initialValues["tax_rate"] = selectedTax;
  initialValues["dealer_guaranty"] = "";
  initialValues["duration"] = "";
  initialValues["distance"] = "";
  // });
  return initialValues;
};
export const WIZARDE_VEHICLE_BILL_OF_SALE_INSURANCE = (
  data = [],
  selectedTax = []
) => {
  let initialValues = {};
  // data?.UcdaPreset?.TaxRates.map((field) => {
  //   initialValues[field?.MidUcdaTaxRates.tax_rate_name] = field?.MidUcdaTaxRates.tax_rate_amount;
  // });
  selectedTax?.map((item) => {
    initialValues[`tax_${item?.id}`] = item.rate;
  });
  // fieldsTax.map((tax) => {
  initialValues["tax_rate"] = selectedTax;
  initialValues["insurance_deductible"] = "";
  initialValues["insurance_duration"] = "";
  initialValues["type"] = "";
  // });
  return initialValues;
};
export const ADD_PDF_INVENTORY_Eblock = (select, pdfData) => {
  return {
    submit_type: 2,
    vin_number: pdfData?.info?.vin_number,
    how_enter_status: select ? select : 1,
    pdf: "",
    odometer: pdfData?.info?.odometer ? pdfData?.info?.odometer : "",
    odometerType: pdfData?.info?.odometerType
      ? pdfData?.info?.odometerType
      : "",
    paymentDate: pdfData?.info?.paymentDate
      ? moment(pdfData?.info?.paymentDate).format("MM-DD-YY")
      : "",
    pdfPath: pdfData?.info?.pdfPath ? pdfData?.info?.pdfPath : "",
    postalCode: pdfData?.info?.postalCode ? pdfData?.info?.postalCode : "",
    purchaseDate: pdfData?.info?.purchaseDate
      ? moment(pdfData?.info?.purchaseDate).format("MM-DD-YY")
      : "",
    sellerName: pdfData?.info?.sellerName ? pdfData?.info?.sellerName : "",
    // invoices: {
    //   buyFee: [
    //     pdfData?.info?.invoices?.buyFee[0]
    //       ? pdfData?.info?.invoices?.buyFee[0]
    //       : "",
    //     pdfData?.info?.invoices?.buyFee[1]
    //       ? pdfData?.info?.invoices?.buyFee[1]
    //       : "",
    //   ],
    //   processingFee: [
    //     pdfData?.info?.invoices?.processingFee[0]
    //       ? pdfData?.info?.invoices?.processingFee[0]
    //       : "",
    //     pdfData?.info?.invoices?.processingFee[1]
    //       ? pdfData?.info?.invoices?.processingFee[1]
    //       : "",
    //   ],
    //   sellPrice: [
    //     pdfData?.info?.invoices?.sellPrice[0]
    //       ? pdfData?.info?.invoices?.sellPrice[0]
    //       : "",
    //     pdfData?.info?.invoices?.sellPrice[1]
    //       ? pdfData?.info?.invoices?.sellPrice[1]
    //       : "",
    //   ],
    //   transferFee: [
    //     pdfData?.info?.invoices?.transferFee?.length !== 0
    //       ? pdfData?.info?.invoices?.transferFee[0]
    //         ? pdfData?.info?.invoices?.transferFee[0]
    //         : 0
    //       : 0,
    //     pdfData?.info?.invoices?.transferFee?.length !== 0
    //       ? pdfData?.info?.invoices?.transferFee[1]
    //         ? pdfData?.info?.invoices?.transferFee[1]
    //         : 0
    //       : 0,
    //   ],
    //   transportFee: [
    //     pdfData?.info?.invoices?.transportFee?.length !== 0
    //       ? pdfData?.info?.invoices?.transportFee[0]
    //         ? pdfData?.info?.invoices?.transportFee[0]
    //         : 0
    //       : 0,
    //     pdfData?.info?.invoices?.transportFee?.length !== 0
    //       ? pdfData?.info?.invoices?.transportFee[1]
    //         ? pdfData?.info?.invoices?.transportFee[1]
    //         : 0
    //       : 0,
    //   ],
    //   taxType: pdfData?.info?.invoices?.taxType
    //     ? pdfData?.info?.invoices?.taxType
    //     : "",
    //   totalPrice: pdfData?.info?.invoices?.totalPrice
    //     ? pdfData?.info?.invoices?.totalPrice
    //     : "",
    //   // taxType: pdfData?.info?.invoices?.taxType ? pdfData?.info?.invoices?.taxType : "",
    //   totalTax: pdfData?.info?.invoices?.totalTax
    //     ? pdfData?.info?.invoices?.totalTax
    //     : "",
    // },
    invoices: {
      fees: Object?.fromEntries(
        pdfData?.info?.invoices?.fees?.map((item) => [
          item?.name,
          { name: item?.name, value: item?.value, tax: item?.tax },
        ]) || []
      ),
      feesTaxPercent: pdfData?.info?.invoices?.feesTaxPercent
        ? pdfData?.info?.invoices?.feesTaxPercent
        : "",
      sellPrice: pdfData?.info?.invoices?.sellPrice
        ? pdfData?.info?.invoices?.sellPrice
        : "",
      feesTaxType: pdfData?.info?.invoices?.feesTaxType
        ? pdfData?.info?.invoices?.feesTaxType
        : "",
      // taxType: pdfData?.info?.invoices?.taxType ? pdfData?.info?.invoices?.taxType : "",
      // totalTax: pdfData?.info?.invoices?.value
      //   ? pdfData?.info?.invoices?.value
      //   : "",
    },
    url: pdfData?.url,
  };
};
export const ADD_PDF_INVENTORY_TRADEREV2 = (select, pdfData) => {
  return {
    submit_type: 2,
    vin_number: pdfData?.info?.vin_number,
    how_enter_status: select ? select : 1,
    pdf: "",
    odometer: pdfData?.info?.odometer ? pdfData?.info?.odometer : "",
    odometerType: pdfData?.info?.odometerType
      ? pdfData?.info?.odometerType
      : "",
    paymentDate: pdfData?.info?.paymentDate
      ? moment(pdfData?.info?.paymentDate).format("MM-DD-YY")
      : "",
    pdfPath: pdfData?.info?.pdfPath ? pdfData?.info?.pdfPath : "",
    postalCode: pdfData?.info?.postalCode ? pdfData?.info?.postalCode : "",
    purchaseDate: pdfData?.info?.purchaseDate
      ? pdfData?.info?.purchaseDate
      : "",
    sellerName: pdfData?.info?.sellerName ? pdfData?.info?.sellerName : "",
    invoices: {
      history: [
        pdfData?.info?.invoices?.history[0]
          ? pdfData?.info?.invoices?.history[0]
          : "",
        pdfData?.info?.invoices?.history[1]
          ? pdfData?.info?.invoices?.history[1]
          : "",
      ],
      registration: [
        pdfData?.info?.invoices?.registration[0]
          ? pdfData?.info?.invoices?.registration[0]
          : "",
        pdfData?.info?.invoices?.registration[1]
          ? pdfData?.info?.invoices?.registration[1]
          : "",
      ],
      sellPrice: [
        pdfData?.info?.invoices?.sellPrice[0]
          ? pdfData?.info?.invoices?.sellPrice[0]
          : "",
        pdfData?.info?.invoices?.sellPrice[1]
          ? pdfData?.info?.invoices?.sellPrice[1]
          : "",
      ],
      transaction: [
        pdfData?.info?.invoices?.transaction[0]
          ? pdfData?.info?.invoices?.transaction[0]
          : "",
        pdfData?.info?.invoices?.transaction[1]
          ? pdfData?.info?.invoices?.transaction[1]
          : "",
      ],
      transportation: [
        pdfData?.info?.invoices?.transportation[0]
          ? pdfData?.info?.invoices?.transportation[0]
          : "",
        pdfData?.info?.invoices?.transportation[1]
          ? pdfData?.info?.invoices?.transportation[1]
          : "",
      ],
      totalPrice: pdfData?.info?.invoices?.totalPrice
        ? pdfData?.info?.invoices?.totalPrice
        : "",
      taxType: pdfData?.info?.invoices?.taxType
        ? pdfData?.info?.invoices?.taxType
        : "",
      totalTax: pdfData?.info?.invoices?.totalTax
        ? pdfData?.info?.invoices?.totalTax
        : "",
    },
  };
};
