import React from "react";

const CommentDeposit = ({ formik }) => {
  return (
    <div className="w-100 row px-0 m-0 pt-0 pb-3">
      <div className="p-0 m-0 w-100 py-2 d-flex justify-content-between align-items-center">
        <h2 className="p-0 m-0 mx-2">Comment</h2>
      </div>
      <div className="p-0 m-0 w-100 row p-2">
        <div className="form-group p-0 m-0 col-12 col-md-12 col-lg-12 p-2">
          <label>Comment</label>
          <textarea
            className="form-control form_textarea-style "
            id="comment"
            name="comment"
            value={formik.values.comment}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            rows={4}
          />
        </div>
      </div>
    </div>
  );
};

export default CommentDeposit;
